"use client";
import { useState, useRef, useEffect } from "react";
import classes from "./style.module.css";

interface Props {
  summary: string;
  detail: string;
  className?: string;
}

export default function Faq({ summary, detail, className }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const detailsRef = useRef<HTMLDetailsElement>(null);

  const handleToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isAnimating) {
      setIsAnimating(true);
      setIsOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (contentRef.current && detailsRef.current) {
      const content = contentRef.current;
      const details = detailsRef.current;
      const animationDuration = 400; // ms

      const animate = () => {
        if (isOpen) {
          // Opening animation
          details.open = true;
          content.style.maxHeight = "0px";
          content.style.opacity = "0";

          // Force a reflow
          content.offsetHeight;

          requestAnimationFrame(() => {
            content.style.maxHeight = "1000px";
            content.style.opacity = "1";
          });

          setTimeout(() => {
            setIsAnimating(false);
          }, animationDuration);
        } else {
          // Closing animation
          content.style.maxHeight = `${content.scrollHeight}px`;

          // Force a reflow
          content.offsetHeight;

          requestAnimationFrame(() => {
            content.style.maxHeight = "0px";
            content.style.opacity = "0";
          });

          setTimeout(() => {
            details.open = false;
            setIsAnimating(false);
          }, animationDuration);
        }
      };

      animate();
    }
  }, [isOpen]);

  return (
    <details
      ref={detailsRef}
      className={`${classes.faq} ${className}`}
      style={{ fontFamily: "Roboto, sans-serif" }}
onClick={handleToggle}
    >
      <summary className={classes.summary} >
        <div className={classes.summaryText}>
          <span>{summary}</span>
          <div className={classes.plus}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 2V0H14V2H0Z" fill="#1C1B1F" />
            </svg>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={classes.horizontal}
              style={{
                transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                opacity: isOpen ? 0 : 1,
                transition:
                  "transform 0.4s ease-in-out, opacity 0.4s ease-in-out",
              }}
            >
              <path d="M0 2V0H14V2H0Z" fill="#1C1B1F" />
            </svg>
          </div>
        </div>
        <div className={classes.divider}></div>
      </summary>
      <div
        ref={contentRef}
        className={classes.detail}
        style={{
          maxHeight: "0px",
          opacity: 0,
          overflow: "hidden",
          transition: "max-height 0.3s ease-i-out, opacity 0.3s ease-in-out",
        }}
      >
        <p dangerouslySetInnerHTML={{ __html: detail }} />
        <div className={classes.divider}></div>
      </div>
    </details>
  );
}
