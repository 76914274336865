"use client";

// Local imports
import Features from "./Features";
import Fonts from "./Fonts";
import Showcase from "./Showcase";
import Links from "./Links";
import Faqs from "./Faqs";
import languageConfig from "@/export/config";

// Component props for landing
interface Props {
  platform: "android" | "ios" | "other";
}

export default function Landing({ platform }: Props) {
  return (
    <section
      style={{
        height: "auto",
        transition: "height 0.3s ease",
      }}
    >
      <Features />
      {languageConfig?.routes.fonts ? (
        <Fonts />
      ) : (
        <div style={{ height: "100px" }} />
      )}
      <Showcase platform={platform} />
      <Links />
      <Faqs />
    </section>
  );
}
