"use client";

// Local imports
import Copy from "./FeatureList/Copy/Copy";
import Dark from "./FeatureList/Dark/Dark";
import Draft from "./FeatureList/Draft/Draft";
import EnglishToLanguage from "./FeatureList/EnglishToLanguage/EnglishToLanguage";
import Tool from "./FeatureList/Tools/Tools";
import VoiceTyping from "./FeatureList/VoiceTyping/VoiceTyping";
import classes from "./style.module.css";
import languageConfig from "@/export/config";

export default function Features() {
  const isOdia = languageConfig?.key === "or"; // Check if the language is Odia

  return (
    <div className={`${classes.features}`}>
      <h1>{languageConfig?.routes.index.features.title}</h1>
      <div className={`${classes.cards} ${isOdia ? classes.cardsOdia : ""}`}>
        <EnglishToLanguage
          className={isOdia ? "odiaTop" : ""}
          title={languageConfig?.routes.index.features.items[0]?.title || ""}
          description={
            languageConfig?.routes.index.features.items[0]?.description || ""
          }
          language={
            languageConfig?.routes.index.features.items[0]?.language || ""
          }
        />
        {languageConfig?.key !== "or" && (
          <VoiceTyping
            title={languageConfig?.routes.index.features.items[1].title || ""}
            description={
              languageConfig?.routes.index.features.items[1].description || ""
            }
          />
        )}

        <Copy
          className={isOdia ? "odiaTop" : ""}
          title={languageConfig?.routes.index.features.items[2].title || ""}
          description={
            languageConfig?.routes.index.features.items[2].description || ""
          }
        />
        <Draft
          className={isOdia ? "odiaTop" : ""}
          title={languageConfig?.routes.index.features.items[3].title || ""}
          appName={languageConfig?.routes.index.features.items[3].appName || ""}
          previewText={
            languageConfig?.routes.index.features.items[3].previewText || ""
          }
          description={
            languageConfig?.routes.index.features.items[3].description || ""
          }
        />
        <Tool
          className={isOdia ? "odiaBottom1" : ""}
          title={languageConfig?.routes.index.features.items[4].title || ""}
          description={
            languageConfig?.routes.index.features.items[4].description || ""
          }
        />
        <Dark
          className={isOdia ? "odiaBottom2" : ""}
          title={languageConfig?.routes.index.features.items[5].title || ""}
          description={
            languageConfig?.routes.index.features.items[5].description || ""
          }
        />
      </div>
    </div>
  );
}
