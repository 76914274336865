import classes from "./style.module.css";

// Component props of VoiceTyping feature card
export default function VoiceTyping({
  title,
  description,
}: {
  title: string;
  description: string;
}) {
  return (
    <div className={`${classes.card}`}>
      <div className={`${classes.featureArt}`}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.voiceTyping}`}>
            <svg
              height="15px"
              version="1.1"
              viewBox="0 0 14 21"
              width="10px"
              xmlns="http://www.w3.org/2000/svg"
              style={{ transform: "translate(0%, -5%)" }}
            >
              <title />
              <desc />
              <defs />
              <g
                fill="none"
                fillRule="evenodd"
                id="Page-1"
                stroke="none"
                stroke-width="1"
              >
                <g
                  fill="#000000"
                  id="Icons-AV"
                  transform="translate(-45.000000, -43.000000)"
                >
                  <g id="mic-none" transform="translate(45.000000, 43.500000)">
                    <path
                      d="M7,12 C8.7,12 10,10.7 10,9 L10,3 C10,1.3 8.7,0 7,0 C5.3,0 4,1.3 4,3 L4,9 C4,10.7 5.3,12 7,12 L7,12 Z M5.8,2.9 C5.8,2.2 6.3,1.7 7,1.7 C7.7,1.7 8.2,2.2 8.2,2.9 L8.2,9.1 C8.2,9.8 7.7,10.3 7,10.3 C6.3,10.3 5.8,9.8 5.8,9.1 L5.8,2.9 L5.8,2.9 Z M12.3,9 C12.3,12 9.8,14.1 7,14.1 C4.2,14.1 1.7,12 1.7,9 L0,9 C0,12.4 2.7,15.2 6,15.7 L6,19 L8,19 L8,15.7 C11.3,15.2 14,12.4 14,9 L12.3,9 L12.3,9 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <p>Voice Typing</p>
          </div>
          <div className={`${classes.listening}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="#2a8451"
              color="#2a8451"
            >
              <mask
                id="mask0_1525_3969"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="25"
                height="25"
              >
                <rect
                  x="0.0908203"
                  y="0.392334"
                  width="24"
                  height="24"
                  fill="#D9D9D9"
                />
              </mask>
              <g mask="url(#mask0_1525_3969)">
                <path
                  d="M8.09082 16.3923H16.0908V8.39233H8.09082V16.3923ZM12.0908 22.3923C10.7075 22.3923 9.40749 22.1298 8.19082 21.6048C6.97415 21.0798 5.91582 20.3673 5.01582 19.4673C4.11582 18.5673 3.40332 17.509 2.87832 16.2923C2.35332 15.0757 2.09082 13.7757 2.09082 12.3923C2.09082 11.009 2.35332 9.709 2.87832 8.49233C3.40332 7.27567 4.11582 6.21733 5.01582 5.31733C5.91582 4.41733 6.97415 3.70483 8.19082 3.17983C9.40749 2.65483 10.7075 2.39233 12.0908 2.39233C13.4742 2.39233 14.7742 2.65483 15.9908 3.17983C17.2075 3.70483 18.2658 4.41733 19.1658 5.31733C20.0658 6.21733 20.7783 7.27567 21.3033 8.49233C21.8283 9.709 22.0908 11.009 22.0908 12.3923C22.0908 13.7757 21.8283 15.0757 21.3033 16.2923C20.7783 17.509 20.0658 18.5673 19.1658 19.4673C18.2658 20.3673 17.2075 21.0798 15.9908 21.6048C14.7742 22.1298 13.4742 22.3923 12.0908 22.3923ZM12.0908 20.3923C14.3242 20.3923 16.2158 19.6173 17.7658 18.0673C19.3158 16.5173 20.0908 14.6257 20.0908 12.3923C20.0908 10.159 19.3158 8.26733 17.7658 6.71733C16.2158 5.16733 14.3242 4.39233 12.0908 4.39233C9.85749 4.39233 7.96582 5.16733 6.41582 6.71733C4.86582 8.26733 4.09082 10.159 4.09082 12.3923C4.09082 14.6257 4.86582 16.5173 6.41582 18.0673C7.96582 19.6173 9.85749 20.3923 12.0908 20.3923Z"
                  fill="#2a8451"
                />
              </g>
            </svg>
            <p>Listening...</p>
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
