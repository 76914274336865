import classes from "./style.module.css";

// Component props of VoiceTyping feature card
export default function Dark({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) {
  return (
    <div className={`${classes.card} ${className}`}>
      <div className={`${classes.featureArt}`}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.darkDisabled}`}>
            <div className={`${classes.darkText}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24"
                style={{ transform: "translate(0%, -5%)" }}
              >
                <path
                  fill="currentColor"
                  d="M9.37 5.51A7.35 7.35 0 0 0 9.1 7.5c0 4.08 3.32 7.4 7.4 7.4c.68 0 1.35-.09 1.99-.27A7.014 7.014 0 0 1 12 19c-3.86 0-7-3.14-7-7c0-2.93 1.81-5.45 4.37-6.49M12 3a9 9 0 1 0 9 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 0 1-4.4 2.26a5.403 5.403 0 0 1-3.14-9.8c-.44-.06-.9-.1-1.36-.1"
                ></path>
              </svg>
              <p>Dark Mode</p>
            </div>
            <div className={`${classes.darkDisabledSwitch}`}>
              <div className={`${classes.darkSwitchCircle}`}></div>
            </div>
          </div>
          <div className={`${classes.darkEnabled}`}>
            <div className={`${classes.darkText}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2rem"
                height="1.2rem"
                viewBox="0 0 24 24"
                style={{ transform: "translate(0%, -5%)" }}
              >
                <path
                  fill="currentColor"
                  d="M9.37 5.51A7.35 7.35 0 0 0 9.1 7.5c0 4.08 3.32 7.4 7.4 7.4c.68 0 1.35-.09 1.99-.27A7.014 7.014 0 0 1 12 19c-3.86 0-7-3.14-7-7c0-2.93 1.81-5.45 4.37-6.49M12 3a9 9 0 1 0 9 9c0-.46-.04-.92-.1-1.36a5.389 5.389 0 0 1-4.4 2.26a5.403 5.403 0 0 1-3.14-9.8c-.44-.06-.9-.1-1.36-.1"
                ></path>
              </svg>
              <p>Dark Mode</p>
            </div>
            <div className={`${classes.darkEnabledSwitch}`}>
              <div className={`${classes.darkSwitchCircle}`}></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
