/** @type {import('next').NextConfig} */
const nextConfig = {
  productionBrowserSourceMaps: true,
  reactStrictMode: false,
  trailingSlash: true,
  webpack(config) {
    // Grab the existing rule that handles SVG imports
    const fileLoaderRule = config.module.rules.find((rule) =>
      rule.test?.test?.(".svg"),
    );

    config.module.rules.push(
      // Reapply the existing rule, but only for svg imports ending in ?url
      {
        ...fileLoaderRule,
        test: /\.svg$/i,
        resourceQuery: /url/, // *.svg?url
      },
      // Convert all other *.svg imports to React components
      {
        test: /\.svg$/i,
        issuer: fileLoaderRule.issuer,
        resourceQuery: { not: [...fileLoaderRule.resourceQuery.not, /url/] }, // exclude if *.svg?url
        use: ["@svgr/webpack"],
      },
    );

    // Modify the file loader rule to ignore *.svg, since we have it handled now.
    fileLoaderRule.exclude = /\.svg$/i;

    return config;
  },

  // Get the static export if the export command is given
  output: process.env.IS_STATIC_EXPORT ? "export" : undefined,
  basePath: process.env.NEXT_PUBLIC_BASE_PATH || undefined,

  // Set custom export directory if it is given
  distDir:
    (process.env.NEXT_PUBLIC_BASE_PATH &&
      process.env.IS_STATIC_EXPORT &&
      `out/${process.env.NEXT_PUBLIC_BASE_PATH}`) ||
    (process.env.NEXT_PUBLIC_LANGUAGE &&
      process.env.IS_STATIC_EXPORT &&
      `out/${process.env.NEXT_PUBLIC_LANGUAGE}`) ||
    ".next",

  // Custom loader for images
  images: {
    loader: "custom",
    loaderFile: "./image-loader.js",
  },
};

module.exports = nextConfig;
