"use client";

// External imports
import Image from "next/image";
import NextLink from "next/link";

// Local imports
import classes from "./style.module.css";
import AppStoreButton from "@/app/assets/vectors/AppStoreButton";
import GooglePlayButton from "@/app/assets/vectors/GooglePlayButton";
import languageConfig from "@/export/config";

// Component props for showcase
interface Props {
  platform: "android" | "ios" | "other";
}

export default function Showcase({ platform }: Props) {
  return (
    <div className={`${classes.showcase}`} style={{ fontFamily: "Roboto" }}>
      <Image
        className={`${classes.imageBig}`}
        src="/showcase.webp"
        width={659}
        height={1112}
        alt={`${languageConfig?.app.name} inside a mobile frame`}
      />
      <div className={`${classes.content}`}>
        <Image
          className={`${classes.ratingImage}`}
          src="/rating.webp"
          width={530}
          height={432}
          alt={`Overall rating of the ${languageConfig?.app.name} application`}
        />
        <h2 className={`${classes.title}`}>
          {languageConfig?.routes.index.showcase.title}
        </h2>
        <p>{languageConfig?.routes.index.showcase.description}</p>
        <Image
          className={`${classes.imageSmall}`}
          src="/showcase.webp"
          width={659}
          height={1112}
          alt={`${languageConfig?.app.name} inside a mobile frame`}
        />
        <div className={`${classes.cta}`}>
          <div className={`${classes.links}`}>
            {languageConfig?.app.links.playStore && (
              <a
                href={`${languageConfig?.app.links.playStore}`}
                aria-label="Link to Google Play Store"
                target="_blank"
              >
                <GooglePlayButton />
              </a>
            )}
            {languageConfig?.app.links.appStore && (
              <a
                href={`${languageConfig?.app.links.appStore}`}
                aria-label="Link to Apple App Store"
                target="_blank"
              >
                <AppStoreButton />
              </a>
            )}
          </div>
          {platform !== "ios" && (
            <NextLink href="/apk" className={`${classes.apk}`}>
              {languageConfig?.app.name} APK
            </NextLink>
          )}
        </div>
      </div>
    </div>
  );
}
