"use client";

// Local imports
import classes from "./style.module.css";
import ClusterDevLogo from "@/app/assets/vectors/ClusterDevLogo";
import languageConfig from "@/export/config";

export default function Footer() {
  return (
    <footer className={`${classes.footer}`}>
      <div className={`${classes.container}`}>
        <a
          href="https://clusterdev.com/"
          aria-label="Link to the ClusterDev website."
          target="_blank"
        >
          <ClusterDevLogo width={148} />
        </a>
        <div className={`${classes.links}`}>
          <a
            href={`${languageConfig?.globalComponents.footer.links.privacy}`}
            className={`${classes.link}`}
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
}
