"use client";

// Local imports
import classes from "./style.module.css";

// Component props of the other language tool link
interface Props {
  title: string;
  lang: string;
  href: string;
}

export default function Link({ title, lang, href }: Props) {
  return (
    <a href={href} className={`${classes.link}`} target="_blank">
      <span className={`${classes.linkTitle}`}>{title}</span>
      <span className={`${classes.linkLang}`}>{lang}</span>
    </a>
  );
}
