"use client";

import { useEffect, useState, useCallback } from "react";
import Landing from "./components/Landing";
import Footer from "./components/Footer";
import dynamic from "next/dynamic";
import Skeleton from "@clusterdev/desh-web-editor/skeleton";
import "@clusterdev/desh-web-editor/styles";
import languageConfig from "@/export/config";
import "./editor.css";
import Banner from "./components/Banner";

// Extend Window interface
interface CustomWindow extends Window {
  localStoragSuffix?: string;
}

const DeshEditor = dynamic(
  () =>
    import("@clusterdev/desh-web-editor").then((module) => {
      console.log("Loaded Desh Editor");
      import("@sentry/browser").then((sentry) => {
        sentry.init({
          release: process.env.NEXT_PUBLIC_RELEASE,
          dsn: "https://a2fdbf4335a0f0cc6454c6210d9f6683@sentry.desh-api.dev/5",
          integrations: [
            sentry.browserTracingIntegration(),
            sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
            }),
          ],
          // Tracing
          tracesSampleRate: 0.1, //  Capture 10% of the transactions
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ["localhost"],
          // Session Replay
          replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
          replaysOnErrorSampleRate: 0.3, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        console.log("Sentry initialized");
      });

      return module.default;
    }),
  {
    ssr: false,
    loading: () => <Skeleton config={languageConfig?.editor} />,
  },
);

const getFontConfig = () => {
  return (
    languageConfig?.routes.fonts?.fonts.reduce(
      (acc, font) => {
        acc[font.cssName] = font.titleMl;
        return acc;
      },
      {} as Record<string, string>,
    ) ?? languageConfig?.editor?.fonts
  );
};

export default function IndexPage() {
  const [platform, setPlatform] = useState<"android" | "ios" | "other">(
    "other",
  );
  const [isVisible, setIsVisible] = useState(false);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const getPlatform = useCallback(() => {
    const ua = navigator.userAgent;
    if (ua.includes("Android")) return "android";
    else if (ua.includes("iPhone")) return "ios";
    else return "other";
  }, []);

  const handleScroll = useCallback(() => {
    if (window.scrollY > window.innerHeight / 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const detectKeyboard = useCallback(() => {
    const initialHeight = window.innerHeight;

    const resizeHandler = () => {
      const currentHeight = window.innerHeight;

      if (
        (platform === "android" || platform === "ios") &&
        window.innerWidth < 760
      ) {
        if (currentHeight < initialHeight * 0.85) {
          setIsKeyboardOpen(true);
          document.body.classList.add("keyboard-open");
        } else {
          document.body.classList.remove("keyboard-open");
          setIsKeyboardOpen(false);
        }
      }
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [platform]);

  useEffect(() => {
    (window as CustomWindow).localStoragSuffix =
      process.env.NEXT_PUBLIC_LANGUAGE;

    if (process.env.NODE_ENV === "production") {
      console.log = () => {};
      // console.warn = () => {};
    }

    setPlatform(getPlatform());

    if (platform === "android" || platform === "ios") {
      if (
        !localStorage.getItem(
          `isBannerClosed/${process.env.NEXT_PUBLIC_LANGUAGE}`,
        )
      ) {
        localStorage.setItem(
          `isBannerClosed/${process.env.NEXT_PUBLIC_LANGUAGE}`,
          "false",
        );
      }
    }

    window.addEventListener("scroll", handleScroll);

    const cleanup = detectKeyboard();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cleanup();
    };
  }, [platform, getPlatform, handleScroll, detectKeyboard]);

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {!isKeyboardOpen && platform !== "other" && (
        <Banner platform={platform} />
      )}
      <DeshEditor
        config={{
          ...languageConfig?.editor,
          fonts: {
            [languageConfig!.editor!.defaultFont]: "Default",
            ...getFontConfig(),
            [languageConfig!.editor!.defaultFont]: "Default",
          },
        }}
      />
      <div className="editor-divider"></div>
      <Landing platform={platform} />
      <Footer />
      <div
        className={`backToTop ${isVisible ? "visible" : ""}`}
        onClick={handleBackToTop}
      >
        <p style={{ fontFamily: "Roboto", userSelect: "none" }}>Go To Editor</p>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_1_960"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="24"
          >
            <rect x="0.531067" width="24" height="24" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_1_960)">
            <path
              d="M11.5311 20V7.825L5.93107 13.425L4.53107 12L12.5311 4L20.5311 12L19.1311 13.425L13.5311 7.825V20H11.5311Z"
              fill="#43753D"
            />
          </g>
        </svg>
      </div>
    </>
  );
}
