import classes from "./style.module.css";

// Component props of VoiceTyping feature card
export default function Copy({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) {
  return (
    <div className={`${classes.card} ${className}`}>
      <div className={`${classes.featureArt}`}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.copyText}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <mask
                id="mask0_1_1079"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="21"
                height="20"
              >
                <rect x="0.25" width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1_1079)">
                <path
                  d="M7.75 15C7.29167 15 6.89931 14.8368 6.57292 14.5104C6.24653 14.184 6.08333 13.7916 6.08333 13.3333V3.33329C6.08333 2.87496 6.24653 2.4826 6.57292 2.15621C6.89931 1.82982 7.29167 1.66663 7.75 1.66663H15.25C15.7083 1.66663 16.1007 1.82982 16.4271 2.15621C16.7535 2.4826 16.9167 2.87496 16.9167 3.33329V13.3333C16.9167 13.7916 16.7535 14.184 16.4271 14.5104C16.1007 14.8368 15.7083 15 15.25 15H7.75ZM7.75 13.3333H15.25V3.33329H7.75V13.3333ZM4.41667 18.3333C3.95833 18.3333 3.56597 18.1701 3.23958 17.8437C2.91319 17.5173 2.75 17.125 2.75 16.6666V4.99996H4.41667V16.6666H13.5833V18.3333H4.41667Z"
                  fill="#444746"
                />
              </g>
            </svg>
            <p>Copy Text</p>
          </div>
          <div className={`${classes.copied}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5rem"
              height="1.5rem"
              viewBox="0 0 24 24"
              style={{ transform: "translate(0%, -5%)" }}
            >
              <path
                fill="currentColor"
                d="M21 7L9 19l-5.5-5.5l1.41-1.41L9 16.17L19.59 5.59z"
              ></path>
            </svg>
            <p>Copied!</p>
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
