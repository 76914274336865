import classes from "./style.module.css";

// Component props of EnglishToLanguage feature card
export default function EnglishToLanguage({
  title,
  language,
  className,
  description,
}: {
  title: string;
  language: string;
  description: string;
  className?: string;
}) {
  return (
    <div className={`${classes.card} ${className}`}>
      <div className={`${classes.featureArt}`}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.englishToMalayalam}`}>
            <p className={`${classes.malayalamFocused}`}>{language}</p>
            <p>English</p>
          </div>
          <div
            className={`${classes.malayalamToEnglish}`}
            style={{ fontFamily: "Noto Sans Malayalam" }}
          >
            <p>{language}</p>
            <p className={`${classes.englishFocused}`}>English</p>
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
