import classes from "./style.module.css";
import Image from "next/image";

// Component props of VoiceTyping feature card
export default function Draft({
  title,
  className,
  appName,
  description,
  previewText,
}: {
  title: string;
  appName: string;
  description: string;
  previewText: string;
  className?: string;
}) {
  return (
    <div className={`${classes.card} ${className}`}>
      <div className={`${classes.featureArt}`} aria-hidden={true}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.sideBar}`}>
            <div className={`${classes.logoHolder}`}>
              <Image
                src={"/icons/header-icon.svg"}
                alt="Header icon"
                width={50}
                height={50}
                className={`${classes.logo}`}
              />
              <p>{appName}</p>
            </div>
            <div className={`${classes.newFile}`}>
              <svg
                width="0.7rem"
                height="0.7rem"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill="#43753D" />
              </svg>
              <p>New File</p>
            </div>
            <p className={`${classes.time}`}>Last Week</p>
            <p className={`${classes.draft1}`}>{previewText}</p>
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
