import classes from "./style.module.css";
import Image from "next/image";

// Component props of VoiceTyping feature card
export default function Tool({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) {
  return (
    <div className={`${classes.card} ${className}`}>
      <div className={`${classes.featureArt}`} aria-hidden={true}>
        <div className={`${classes.featureArtHolder}`}>
          <div className={`${classes.toolImg}`}>
            <Image
              src={`/images/image1.png`}
              alt={`Icon of ${title}`}
              width={600}
              height={600}
              className={`${classes.image1}`}
            />
            <Image
              src={`/images/image2.png`}
              alt={`Icon of ${title}`}
              width={600}
              height={600}
              className={`${classes.image2}`}
            />
          </div>
        </div>
      </div>
      <div className={`${classes.featureText}`}>
        <h3 className={`${classes.featureTitle}`}>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}
