import languageConfig from "@/export/config";
import classes from "./style.module.css";
import Logo from "@/public/icons/header-icon.svg";

// Component props of banner
interface Props {
  platform: "android" | "ios" | "other";
}

export default function Banner({ platform }: Props) {
  return (
    <div className={` ${classes.banner}`}>
      <div className={`${classes.container}`}>
        <div className={`${classes.logo}`}>
          <div className={`${classes.image}`}>
            <Logo />
          </div>
          <h2 className={`${classes.title}`}>{languageConfig?.app.name}</h2>
        </div>
        <div className={`${classes.content}`}>
          <p>
            Try the best {languageConfig?.app.language} typing app
          </p>
        </div>
      </div>
      <a
        className={`${classes.link}`}
        href={
          platform === "android"
            ? languageConfig?.app.links.playStore
            : platform === "ios"
            ? languageConfig?.app.links.appStore
            : "#"
        }
        target="_blank"
      >
        Install
      </a>
    </div>
  );
}
